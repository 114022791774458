import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo } from 'react'
import Moment from 'react-moment'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Badge,
  ListPageLayout,
  TableBoldColumnContainer,
} from '../../../components'
import {
  BadgeColors,
  NO_DATA_PLACEHOLDER,
  RiskReliefDeviationStatus,
  dateFormat,
  riskReliefDeviationAllOwnersStatus,
  riskReliefDeviationStatuses,
} from '../../../constants/constants'
import { useSearchReviews, useUsers, useWorkspaceDetails } from '../../../hooks'
import { SearchQuery } from '../../../store/reducers/searchReviewReducer'
import { routePaths } from '../../RootPage'
import { workSpaceTabs, workspacePanelTypes } from '../WorkspaceDetailsPage'

interface ExtendedSearchQuery extends SearchQuery {
  key: string
  ownerName: string
  ownerId: string
  searchReviewTitle?: string
  searchQueryText?: string
  publicationDate?: string
  deviationStatus: RiskReliefDeviationStatus
}

interface WorkspaceDeviationsPanelProps {
  statusFilter: RiskReliefDeviationStatus
  deviationOwner: string
}

const WorkspaceDeviationsPanel = ({
  statusFilter,
  deviationOwner,
}: WorkspaceDeviationsPanelProps) => {
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const { findUser } = useUsers({})

  const {
    refreshBatchSearchReviewList,
    refreshSearchReviewListDone,
    searchReviewList,
    loading,
  } = useSearchReviews({
    preventFetch: true,
  })

  const fetchSearchReviews = useCallback(() => {
    const searchReviewIds =
      selectedWorkspace?.config?.riskReliefConfig?.searchReviewIds
    if (searchReviewIds?.length) {
      refreshBatchSearchReviewList(searchReviewIds)
    } else {
      refreshBatchSearchReviewList([])
    }
  }, [selectedWorkspace, refreshBatchSearchReviewList])

  useEffect(() => {
    if (!loading && !refreshSearchReviewListDone) {
      fetchSearchReviews()
    }
  }, [fetchSearchReviews, loading, refreshSearchReviewListDone])

  useEffect(() => {
    if (selectedWorkspace) {
      fetchSearchReviews()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspace])

  const formattedData: ExtendedSearchQuery[] = useMemo(() => {
    if (!searchReviewList?.length) return []

    const allQueries = searchReviewList.flatMap(
      (review) => review.searchQueries
    )

    if (!allQueries.length) return []

    const queryDeviations =
      selectedWorkspace?.config?.riskReliefConfig?.queryDeviations || []

    const relevantSearchQueryIds = queryDeviations.map(
      (queryDeviation) => queryDeviation.searchQueryId
    )

    if (!relevantSearchQueryIds.length) return []

    const matchingQueries = allQueries.filter(
      (query): query is SearchQuery =>
        !!query && relevantSearchQueryIds.includes(query.id)
    )

    const extendedQueries: ExtendedSearchQuery[] = matchingQueries
      .filter((query) => query !== undefined)
      .map((query) => {
        const searchReview = searchReviewList.find(
          (review) => review.id === query.literatureReview
        )

        const ownerId = query.searchActionBy?.id ?? ''
        const ownerName = findUser(ownerId)?.displayName ?? ''

        const deviationStatus =
          queryDeviations.find((d) => d.searchQueryId === query.id)
            ?.deviationStatus ?? ''

        return {
          ...query,
          ownerId,
          ownerName,
          searchReviewTitle: searchReview?.title ?? '',
          searchQueryText: query.searchText,
          publicationDate: query.searchDate,
          key: `${query.searchSource?.id}-${query.id}`,
          deviationStatus: deviationStatus as RiskReliefDeviationStatus,
        } as ExtendedSearchQuery
      })

    return extendedQueries.filter((item) => {
      // match status and owner!
      const statusMatches =
        statusFilter === riskReliefDeviationStatuses.ALL ||
        item.deviationStatus === statusFilter

      const ownerMatches =
        deviationOwner === riskReliefDeviationAllOwnersStatus ||
        item.ownerId === deviationOwner

      return statusMatches && ownerMatches
    })
  }, [
    searchReviewList,
    findUser,
    selectedWorkspace,
    statusFilter,
    deviationOwner,
  ])

  const columns: ColumnsType<ExtendedSearchQuery> = [
    {
      title: t('Search Query'),
      dataIndex: 'searchQueryText',
      key: 'searchQueryText',
      width: '40%',
      sorter: (a: ExtendedSearchQuery, b: ExtendedSearchQuery) =>
        a.searchQueryText && b.searchQueryText
          ? a.searchQueryText.localeCompare(b.searchQueryText)
          : -1,
      render: (text, record) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Historical Review'),
      dataIndex: 'searchReviewTitle',
      key: 'searchReviewTitle',
      width: '20%',
      sorter: (a: ExtendedSearchQuery, b: ExtendedSearchQuery) =>
        a.searchReviewTitle && b.searchReviewTitle
          ? a.searchReviewTitle.localeCompare(b.searchReviewTitle)
          : -1,
      render: (text) => text || NO_DATA_PLACEHOLDER,
    },
    {
      title: t('Date'),
      dataIndex: 'publicationDate',
      key: 'publicationDate',
      className: 'max-with-deviation',
      sorter: (a: ExtendedSearchQuery, b: ExtendedSearchQuery) =>
        a.publicationDate && b.publicationDate
          ? a.publicationDate.localeCompare(b.publicationDate)
          : -1,
      render: (textDate) => (
        <>
          {textDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {textDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: t('Owner'),
      dataIndex: 'ownerName',
      key: 'ownerName',
      className: 'max-with-deviation',
      sorter: (a: ExtendedSearchQuery, b: ExtendedSearchQuery) =>
        a.ownerName && b.ownerName
          ? a.ownerName.localeCompare(b.ownerName)
          : -1,
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: t('Status'),
      dataIndex: 'deviationStatus',
      key: 'deviationStatus',
      sorter: (a: ExtendedSearchQuery, b: ExtendedSearchQuery) =>
        a.deviationStatus && b.deviationStatus
          ? a.deviationStatus.localeCompare(b.deviationStatus)
          : -1,
      render: (text) => (
        <>
          {text ? (
            <Badge
              color={
                text === riskReliefDeviationStatuses.OPENED
                  ? BadgeColors.STATUS_OPENED
                  : BadgeColors.STATUS_CLOSED
              }
            >
              {t(`workspaceDetailsPage.deviationStatus.${text}`)}
            </Badge>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
  ]

  const handleClick = (record: ExtendedSearchQuery) => {
    navigate(
      `${routePaths.WORKSPACE_DETAILS}/${workspaceId}/${workspacePanelTypes.DEVIATIONS}/${workSpaceTabs.INFO}/${record.id}`
    )
  }

  return (
    <ListPageLayout>
      <Table
        data-testid="data-sources-list-table"
        rowClassName="page-list-table-row clickable"
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        loading={loading}
        scroll={{ y: 'calc(100vh - 170px)' }}
        onRow={(record) => ({
          onClick: () => handleClick(record),
        })}
      />
    </ListPageLayout>
  )
}

export default WorkspaceDeviationsPanel
